import React from "react"

import { Container, Row, Col } from "reactstrap"

import Layout from "../components/Layout"
import PageHeader from "../components/PageHeader"
import PageFooter from "../components/PageFooter"
import ProgressiveImage from "../components/ProgressiveImage"

import SEO from "../components/SEO"
import WipeScreen from "../components/WipeScreen"

class SecondPage extends React.Component {
  render() {
    return (
      <>
        <SEO title={"Work | OnePlanet"} slug={"oneplanet"} />
        <Row className="white-container case-study">
          <Container className="mt-5 pt-5">
            <Row className="mt-5 pt-5 no-gutters">
              <Col sm="8" className="super-margin">
                <h1>OnePlanet.com - web based application redesign</h1>
                <br />
                <p>Case Study</p>
              </Col>
            </Row>
          </Container>
        </Row>
        <Row className="case-study-content">
          <Container
            className="hero-container"
            style={{ background: "#fff" }}
            fluid
            tag="section"
          >
            <div className="work-image">
              <ProgressiveImage src="foodcompanion/foodcomp.png" />
            </div>
          </Container>

          <Container className="article-content" tag="section" fluid>
            <Container>
              <Row className="inter-section">
                <Col sm="12">
                  <Col sm="6" className="p-0 section-text float-left">
                    <h3>01 Challenge</h3>
                    <p className="gray">
                      Biologica Diagnostics is a German start-up offering
                      innovative medical laboratory tests. I've been involved in
                      the project from the very begining, playing a key role in
                      the process of building a new product - including
                      strategy, branding, product design, data visualization and
                      digital solutions.
                      <br />
                      <br />
                      The goal was to stand out from the crowd, emphasize
                      brand's innovative approach and develop products that
                      offer an excellent user experience for patients and
                      medical consultants.
                      <br />
                      <br />
                      It was crucial to create a laboratory test that is clear
                      and easy to understand regardless of patients age, and
                      helps physicians to set diagnosis faster and more
                      precisely.
                    </p>
                  </Col>
                  <Col
                    sm={{ size: 4, offset: 2 }}
                    className="p-0 section-text float-left"
                  >
                    <h4>Scope</h4>
                    <p className="gray">
                      Branding / Strategy / Data visualization / Product design
                    </p>
                    <h4>Date</h4>
                    <p className="gray">2018 - 2019</p>
                    <h4>Client & location</h4>
                    <p className="gray">
                      Biologica Diagnostics GmbH / Frankfurt am Main, Germany
                    </p>
                  </Col>
                </Col>
              </Row>
            </Container>
          </Container>
          <Container
            className="imagery-content last-imagery"
            style={{ background: "#edf0fa" }}
            fluid
            tag="section"
          >
            <ProgressiveImage src="foodcompanion/foodcomp.png" />
          </Container>
        </Row>
        <WipeScreen />
        <PageFooter project="biologica" />
      </>
    )
  }
}

SecondPage.Layout = Layout
export default SecondPage
