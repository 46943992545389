import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const renderImage = (value) => {
  const { node } = value
  const { childImageSharp } = node
  return <GatsbyImage image={childImageSharp.gatsbyImageData} alt="dsa" />
}

const ProgressiveImage = (props) => {
  const { src } = props
  return (
    <StaticQuery
      query={graphql`
        {
          images: allFile(
            filter: { extension: { regex: "/jpeg|jpg|png|gif/" } }
          ) {
            edges {
              node {
                extension
                relativePath
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      `}
      render={({ images }) =>
        renderImage(
          images.edges.find((image) => image.node.relativePath === src)
        )
      }
    />
  )
}

export default ProgressiveImage
